import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo/seo"
import Layout from "../components/layout"
import Intro from "../components/about/intro.js"
import SkillList from "../components/about/expertise.js"
import ClientList from "../components/about/clients.js"
import Instagram from "../components/about/instagram.js"

const AboutPage = ({ data }) => {
  const seoTitle = data.craft.entries[0].seoTitle
  const seoDescription = data.craft.entries[0].seoDescription
  const seoUrl = data.craft.entries[0].slug
  const seoImage =
    data.site.siteMetadata.siteUrl +
    data.craft.entries[0].imageFile[0].childImageSharp.fixed.src

  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        pathname={`${seoUrl}`}
        website
      />
      <Intro />
      <SkillList />
      <ClientList />
      <Instagram />
    </Layout>
  )
}

export const aboutSEO = graphql`
  query AboutSEO {
    site {
      siteMetadata {
        siteUrl
      }
    }
    craft {
      entries(section: "about") {
        dateCreated
        dateUpdated
        slug
        url
        uri
        ... on Craft_about_about_Entry {
          seoTitle
          seoDescription
          image: seoImage {
            title
            url(width: 720, height: 475, quality: 100, immediately: true)
          }
          imageFile {
            childImageSharp {
              fixed(width: 600, quality: 90) {
                src
              }
            }
          }
        }
      }
    }
  }
`

export default AboutPage
