import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import styled from "@emotion/styled"

const SkillList = () => {
  const data = useStaticQuery(graphql`
    query Expertise {
      craft {
        entries(section: "about") {
          ... on Craft_about_about_Entry {
            expertise {
              ... on Craft_expertise_skillList_BlockType {
                skillListTitle
                skills {
                  title
                }
              }
            }
          }
        }
      }
    }
  `)

  const skills = data.craft.entries[0].expertise

  const ExpertiseWrapper = styled.section`
    width: 100%;
    display: block;

    @media (min-width: 46rem) {
      margin-top: 4rem;
      margin-bottom: 4rem;
      padding-top: 4rem;
      padding-bottom: 4rem;
    }

    &.homeExpertise {
      background-color: ${props => props.theme.primaryBlue};
    }
  `

  const ExpertiseContainer = styled.div`
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(6, 1fr);
    gap: 2em;
    margin: 4em 0px;

    @media (min-width: 46rem) {
      gap: 4em 3em;
    }
  `

  const ExpertiseColumn = styled.div`
    grid-column-end: span 6;

    @media (min-width: 46rem) {
      grid-column-end: span 2;
    }

    h4 {
      font-size: 1.3rem;
      margin-top: 1.5rem;

      &:after {
        content: ".";
        color: ${props => props.theme.primaryBlue};
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin: 0;
        padding: 0;

        &:before {
          content: "—";
          margin-right: 0.55rem;
        }
      }
    }
  `

  return (
    <ExpertiseWrapper>
      <ExpertiseContainer>
        {skills.map((skillRange, i) => (
          <ExpertiseColumn key={i}>
            <h4>{skillRange.skillListTitle}</h4>
            <ul>
              {skillRange.skills.map((skill, i) => {
                return <li key={i}>{skill.title}</li>
              })}
            </ul>
          </ExpertiseColumn>
        ))}
      </ExpertiseContainer>
    </ExpertiseWrapper>
  )
}

export default SkillList
