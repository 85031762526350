import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"

const Instagram = () => {
  const data = useStaticQuery(graphql`
    query InstagramFeed {
      allInstagramContent(limit: 3) {
        edges {
          node {
            caption {
              text
            }
            images {
              standard_resolution {
                url
              }
            }
            localImage {
              childImageSharp {
                fluid(maxWidth: 450, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  `)

  const theGram = data.allInstagramContent.edges

  const InstagramContainer = styled.section`
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    margin-top: 6rem;
    margin-bottom: 4rem;

    @media (min-width: 46rem) {
      align-items: center;
      grid-template-columns: repeat(6, 1fr);
      gap: 4rem 4.5rem;
    }
  `

  const GramTing = styled.div`
    grid-column-end: span 2;
    transition: transform 0.3s linear;
    will-change: transform;

    @media (min-width: 46rem) {
      &:hover {
        transition: transform 0.33s 0s cubic-bezier(0.51, 0.92, 0.24, 1.15);
        transform: translateY(-0.4rem);

        p {
          transition: transform 0.3s 0.1s cubic-bezier(0.51, 0.92, 0.24, 1.15);
          transform: translateY(-0.2rem);
        }
      }
    }
  `

  const Caption = styled.p`
    margin-top: 0.75rem;
    transition: transform 0.3s linear;
    font-size: 0.9rem;
    will-change: transform;
  `

  return (
    <div className="about__section--instagram">
      <InstagramContainer>
        {theGram.map((insta, i) => (
          <GramTing key={i}>
            <Img
              fluid={insta.node.localImage.childImageSharp.fluid}
              alt={insta.node.caption.text}
              title={insta.node.caption.text}
            />
            <Caption>{insta.node.caption.text}</Caption>
          </GramTing>
        ))}
      </InstagramContainer>
    </div>
  )
}

export default Instagram
