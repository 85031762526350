import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import WavyLink from "../fragments/wavy-link"
import styled from "@emotion/styled"

const ClientList = () => {
  const data = useStaticQuery(graphql`
    query Clients {
      craft {
        entries(section: "about") {
          title
          slug
          ... on Craft_about_about_Entry {
            clients {
              title
              logo: url(width: 200, quality: 100, immediately: true)
            }
          }
        }
      }
    }
  `)

  const clients = data.craft.entries[0].clients

  const ClientsWrapper = styled.div`
    background-color: ${props => props.theme.textColor};
    position: relative;
    margin-top: 4rem;
    margin-bottom: 4rem;

    @media (min-width: 46rem) {
      box-shadow: inset -1.3rem 0px 0px 0px rgba(255, 255, 255, 1),
        inset 1.3rem 0px 0px 0px rgba(255, 255, 255, 1);
    }
  `

  const ClientsContainer = styled.section`
    max-width: 58rem;
    padding-top: 7rem;
    padding-bottom: 7rem;
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 2rem;

    @media (min-width: 46rem) {
      align-items: center;
      gap: 1rem 6rem;
    }

    h4 {
      grid-column-end: span 6;
      /* grid-column-start: 2; */
      color: ${props => props.theme.default.white};
      margin-bottom: 2rem;
      font-size: 1.6rem;
      text-align: center;
      padding: 0 1rem;

      @media (min-width: 46rem) {
        padding: 0 6rem;
      }
    }
  `

  const Client = styled.div`
    grid-column-end: span 3;
    text-align: center;

    @media (min-width: 46rem) {
      grid-column-end: span 2;

      &:hover {
        img {
          transition: transform 0.55s cubic-bezier(0.51, 0.92, 0.24, 1.15);
          transform: translateY(-0.4rem);
        }
      }
    }

    img {
      transition: transform 0.55s cubic-bezier(0.51, 0.92, 0.24, 1.15);
      width: 75%;
      margin: auto;
      filter: invert(1);
      will-change: transform;

      @media (min-width: 46rem) {
        width: 60%;
      }
    }
  `

  const WorkLink = styled.div`
    grid-column-end: span 6;
    /* grid-column-start: 2; */
    margin-top: 2rem;
    text-align: center;
    padding: 0;
  `

  return (
    <ClientsWrapper>
      <ClientsContainer>
        <h4>
          I've had the chance to partner with amazing companies over the years.
          From corporate business to public institutions, creative agencies and
          progressive startups.
        </h4>

        {clients.map((client, i) => (
          <Client key={i}>
            <img
              src={client.logo}
              alt={client.title}
              title={client.title}
              loading="lazy"
            />
          </Client>
        ))}

        <WorkLink>
          <WavyLink
            href={"/work"}
            title="Work"
            text="Take a look at my work"
            textColor="whiteText"
            lineColor="yellowLine blueLineHover"
          />
        </WorkLink>
      </ClientsContainer>
    </ClientsWrapper>
  )
}

export default ClientList
